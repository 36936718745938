<template>
  <v-card>
    <v-card-title>Edit Section</v-card-title>
    <v-card-text>
      <v-form
        ref="formItem"
        @submit.prevent="handleFormSubmit"
      >

        <v-text-field
          v-model="itemData.name"
          label="Name"
          name="name"
          outlined
          placeholder="Name"
          :rules="[required]"
          :error-messages="errorMessages.name"
          @focus="errorMessages.name = null"
        ></v-text-field>

        <v-text-field
          v-model="itemData.slug"
          label="Slug"
          name="slug"
          outlined
          placeholder="Slug"
          :rules="[required]"
          :error-messages="errorMessages.slug"
           @focus="errorMessages.slug = null"
        ></v-text-field>

        <v-btn color="primary" type="submit">
          Save
        </v-btn>

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {onUnmounted, ref, toRef} from '@vue/composition-api'
import store from '@/store'
import itemsStore from './store'
import useSections from "@/views/Content/Sections/composition";
import SeoMeta from '@/components/SeoMeta.vue'
import FormImage from '@/components/Form/UploadImage.vue'
import FormEditor from '@/components/Form/Editor.vue'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'

export default {
  components: {
    SeoMeta,
    FormImage,
    FormEditor,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-content-sections'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, itemsStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const { route } = useRouter()

    const formItem = ref(null)

    const handleFormSubmit = () => {
      if (!formItem.value.validate()) {
        toast.warning('Validation errors')
        return
      }
      updateItem(route.value.params.id, itemData.value)
    }

    const {
      loading,
      itemData,
      seoData,
      errorMessages,
      toast,
      updateItem,
      fetchItem,
      bodyHtml,
    } = useSections()

    fetchItem(route.value.params.id)

    return {
      loading,
      itemData,
      seoData,
      errorMessages,
      formItem,
      toast,
      handleFormSubmit,
      updateItem,
      required,
      bodyHtml,
    }
  },
}
</script>
